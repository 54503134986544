<template>
  <div>
    <div v-if="authUserPermission['stock-calc-fast-by-demand']">
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0"></h6>
          </div>
        </div>
      </base-header>
      <div class="container-fluid mt--6">
        <div class="card mb-4">
          <div class="card-header mt--4 mb--4">
            <div class="row">
              <div class="col-12">
                <h3>
                  Rekomendasi Keputusan penyediaan untuk Material dengan Umur Sisa Pakai
                  Tertentu
                </h3>
                <span>
                  Perhitungan ini digunakan untuk sparepart dengan umur sisa pakai yang
                  diketahui. Perhitungan ini akan merekomendasikan apakah perlu melakukan
                  penyediaan sparepart untuk barang dengan sisa umur pakai tertentu pada
                  sisa masa hidupnya menggunakan analisa Benefit Cost Ratio.
                </span>

                <!-- matrix perhitungan -->
                <el-dialog
                  title="Matrix Perhitungan"
                  :visible.sync="matrixDialogVisible"
                  width="70%"
                  :close-on-click-modal="false"
                  @close="handleDialogClose"
                >
                  <div class="table-container">
                    <table class="matrix-table">
                      <thead>
                        <tr>
                          <th rowspan="5" class="variable-header" style="width: 170px">
                            VARIABEL
                          </th>
                          <th colspan="7" class="method-header">METODE</th>
                          <th
                            rowspan="4"
                            class="bcr-header"
                            style="background-color: #1579ab; width: 90px"
                          >
                            BCR
                          </th>
                        </tr>
                        <tr>
                          <th colspan="3" class="sub-header">N=0</th>
                          <th class="sub-header">N &lt; 30</th>
                          <th class="sub-header">N &gt; 30</th>
                          <th colspan="2" class="sub-header">N &lt; 30 &amp; N&gt; 30</th>
                        </tr>
                        <tr>
                          <th
                            colspan="3"
                            class="sub-header"
                            style="background-color: #1579ab"
                          >
                            NON MOVING
                          </th>
                          <th class="sub-header" style="background-color: #1579ab">
                            Poisson <br />(PP)
                          </th>
                          <th
                            class="sub-header"
                            style="background-color: #1579ab; width: 60px"
                          >
                            Normal <br />(PN)
                          </th>
                          <th class="sub-header" style="background-color: #1579ab">
                            Deterministik <br />(MD)
                          </th>
                          <th class="sub-header" style="background-color: #1579ab">
                            Tak Tentu<br />(MT)
                          </th>
                        </tr>
                        <tr>
                          <th class="sub-header" style="width: 70px">Regret</th>
                          <th class="sub-header" style="width: 115px">
                            Liniear Kerusakan
                          </th>
                          <th class="sub-header" style="width: 140px">
                            Non Liniear Kerusakan
                          </th>
                          <th class="sub-header" style="width: 65px">Poisson</th>
                          <th class="sub-header" style="width: 65px">Model Q</th>
                          <th class="sub-header" style="width: 80px">Tchebycheff</th>
                          <th class="sub-header" style="width: 85px">Deterministik</th>
                        </tr>
                        <tr>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, <br />
                            Ongkos <br />Inventory
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, Ongkos <br />
                            Inventory
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, Ongkos <br />
                            Inventory
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, ROP <br />
                            SS, Min, <br> Max
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, ROP <br />
                            SS, Min, <br> Max
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, ROP <br />
                            TCO, Min,<br> Max
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            EOQ, TCO
                          </th>
                          <th class="sub-header" style="background-color: #1ad0b7">
                            Rekomendasi <br />Pembelian <br />
                            Sparepart
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>*Demand (D)</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>*Lead Time (L)</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>*Unit Price (p)</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>**Stock Effect (Cu)</td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>**Order Cost (A)</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>**Holding Cost (h)</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>**Suku Bunga (i)</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                        </tr>
                        <tr>
                          <td>***Serice Level</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Jumlah Komponen Terpasang (n)</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td>✓</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Sisa Tahun Pemakaian</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>✓</td>
                        </tr>
                      </tbody>
                    </table>
                    <h5>
                      Keterangan: N Merupakan jumlah adanya pengambilan dalam kurun 7
                      tahun terakhir dalam satuan waktu bulan
                    </h5>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="matrixDialogVisible = false">Tutup</el-button>
                  </span>
                </el-dialog>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-16 mt-3">
              <div class="d-flex flex-wrap align-items-start">
                <!-- Plant -->
                <div class="mr-2" style="flex: 0 0 170px; min-width: 70px">
                  <label class="form-control-label">
                    <b>Anak Perusahaan</b>
                    <el-tooltip content="Anak perusahaan dalam PI Grup" placement="top">
                      <sup>
                        <span style="font-size: 10px">
                          <i class="fa fa-exclamation-circle"></i>
                        </span>
                      </sup>
                    </el-tooltip>
                  </label>
                  <el-select
                    v-model="getData.plant"
                    style="width: 100%"
                    :disabled="loadingCalculate || loadingRefresh || ['B000', 'C000', 'D000', 'E000', 'F000', 'G000', 'H000', 'I000', 'J000'].includes(authUser.company_code)"
                    @change="handlePlantChange"
                  >
                    <!-- <el-option value="" label="Choose Plant"></el-option> -->
                    <el-option
                      v-for="plant in plants"
                      :key="plant.code"
                      :value="plant.code"
                      :label="`${plant.name}`"
                    ></el-option>
                  </el-select>
                </div>
                <!-- Material Number -->
                <div class="mr-2" style="flex: 0 0 200px; min-width: 250px">
                  <label class="form-control-label">
                    <b>Material Number</b>
                    <el-tooltip content="Material Spare Part MRO (6XXXXX)" placement="top">
                      <sup>
                        <span style="font-size: 10px">
                          <i class="fa fa-exclamation-circle"></i>
                        </span>
                      </sup>
                    </el-tooltip>
                  </label>
                  <base-inpu>
                    <el-autocomplete v-model="searchQuery" style="width: 100%" :fetch-suggestions="fetchSuggestions" placeholder="Search Material Number" :loading="loadingSearch" @select="handleSelectMaterialNumber" :trigger-on-focus="false">
                      <template slot-scope="{ item }">
                        <div>{{ item.label }}</div>
                          <div :class=" item.label === 'No Data' ? 'text-center' : '' ">{{ item.label }}</div>
                      </template>
                    </el-autocomplete>
                  </base-inpu>
                </div>

                <div class="d-flex align-items-center" style="flex: 1; min-width: 150px; margin-top: 30px">
                  <base-button type="success" @click="handleGetData" :disabled="!getData.plant || !getData.materialNumber || loadingFittingData" :loading="loadingFittingData">
                    Get Data
                  </base-button>

                  <base-button type="danger" @click="resetParameter" :disabled="disableReset || loadingReset || loadingRefresh">
                    Reset
                    <el-tooltip content="Mereset ulang inputan" placement="top">
                      <sup
                        ><span style="font-size: 10px"
                          ><i class="fa fa-exclamation-circle"></i></span
                      ></sup>
                    </el-tooltip>
                  </base-button>

                  <base-button type="warning" @click="openMatrixDialog">
                    Matrix Perhitungan
                    <el-tooltip
                      content="Memunculkan matrix kebutuhan data untuk tipe material data"
                      placement="top"
                    >
                      <sup
                        ><span style="font-size: 10px"
                          ><i class="fa fa-exclamation-circle"></i></span
                      ></sup> </el-tooltip
                    ><i class="fas fa-chart-line" style="margin-right: 4px"></i>
                  </base-button>
                </div>
              </div>
            </div>

            <!-- Baris dengan elemen yang disusun horizontal tanpa ada baris baru -->
            <div v-if="dataFetched">
              <div class="col-16">
                <div class="bg-light p-3 mt-4">
                  <div class="row">
                    <div class="col-md-3">
                      <label class="form-control-label"
                        >
                        <b>Unit Price</b>
                        <el-tooltip
                          content="Harga 1 UoM sparepart dalam satuan rupiah"
                          placement="top"
                        >
                          <sup
                            ><span style="font-size: 10px"
                              ><i class="fa fa-exclamation-circle"></i></span
                          ></sup>
                        </el-tooltip>
                      </label>
                      <input class="form-control" style="width: 100%" placeholder="Unit Price" v-model="formattedUnitPrice" type="text" :disabled="loadingCalculate || loadingRefresh"/>
                    </div>

                    <div class="col-md-3">
                      <label class="form-control-label"
                        >
                        <b>Stock Out Effect</b>
                        <el-tooltip
                          content="Ongkos kerugian akibat ketidaktersediaan sparepart per hari"
                          placement="top"
                        >
                          <sup
                            ><span style="font-size: 10px"
                              ><i class="fa fa-exclamation-circle"></i></span
                          ></sup>
                        </el-tooltip>
                      </label>
                      <!-- <input class="form-control" style="width: 100%" placeholder="Stock Out Effect" type="text" v-model="displayStockOutEffect" @input="updateStockOutEffect" :disabled="loadingCalculate || loadingRefresh" -->
                      <input class="form-control" style="width: 100%" placeholder="Stock Out Effect" type="text" v-model="displayStockOutEffect" :disabled="loadingCalculate || loadingRefresh"
                      />
                    </div>

                    <div class="col-md-3">
                      <label class="form-control-label">
                      <b>Suku Bunga*</b>
                        <el-tooltip
                          content="Suku bunga bank (Cost of money)"
                          placement="top"
                        >
                          <sup
                            ><span style="font-size: 10px"
                              ><i class="fa fa-exclamation-circle"></i></span
                          ></sup>
                        </el-tooltip>
                      </label>
                      <!-- <input class="form-control" style="width: 100%" placeholder="Suku Bunga" v-model="formattedSukuBunga" type="text" :disabled="loadingCalculate || loadingRefresh" @input="updateSukuBunga"/> -->
                      <input class="form-control" style="width: 100%" placeholder="Suku Bunga" v-model="formattedSukuBunga" type="text" :disabled="loadingCalculate || loadingRefresh"/>
                    </div>

                    <div class="col-md-3">
                      <label class="form-control-label"
                        ><b>Sisa Umur Pakai (Tahun)</b>
                        <el-tooltip
                          content="Sisa umur pemakaian sebuah equipment / parent equipment / plant"
                          placement="top"
                        >
                          <sup
                            ><span style="font-size: 10px"
                              ><i class="fa fa-exclamation-circle"></i></span
                          ></sup> </el-tooltip
                      ></label>
                      <input class="form-control" style="width: 100%" placeholder="Sisa Umur Pakai" v-model="sisaUmurPakai" type="text" :disabled="loadingCalculate || disableOrderCost || loadingRefresh"/>
                    </div>
                  </div>

                  <div style="margin-top: 15px;">
                    <span style="font-size: 12px;">*Suku bunga didapatkan dari cost of money (Kredit Modal Kerja)</span>
                  </div>
                </div>
                <div class="text-right mt-3">
                  <button :class="['btn', isDisabledRunCalcButton ? 'btn-dark' : 'btn-success']" @click="calculate" :disabled="isDisabledRunCalcButton">
                    <span v-if="loadingCalculate">
                      <i class="fa fa-spinner fa-spin"></i> Processing...
                    </span>
                    <span v-else> Run Calculation </span>
                  </button>
                </div>
              </div>

              <!-- Kolom Summary -->
              <div class="d-flex justify-content-center mt-4" v-if="summaryVisible">
                <div class="bg-white p-5 rounded" style="border: 1px solid #ccc; border-left: none; border-right: none; text-align: center;">
                  <div style="font-weight: bold; margin-bottom: 20px">Summary</div>
                  <div>
                    <p class="summary-text">
                      Dari analisa benefit cost ratio, untuk material number
                      <span class="font-weight-bold">{{ getData.materialNumber }}</span>
                      Direkomendasikan <br />
                      <span class="font-weight-bold">
                        {{
                          isStockRequired
                            ? "PERLU PENYEDIAAN SPAREPART."
                            : "TIDAK PERLU PENYEDIAAN SPAREPART."
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <skeleton-loading v-if="loadingFittingData"></skeleton-loading>
            <empty v-if="!loadingFittingData && !dataFetched"></empty>
          </div>
        </div>
      </div>
      <!-- <noaccess v-else /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import configUrl from "@/configs/config";
import SkeletonLoading from "../../components/SkeletonLoading.vue";
import Chart from "chart.js/dist/chart.js";

export default {
  components: {},
  data() {
    return {
      getData: {
        plant: null,
        materialNumber: null,
      },
      plants: [
        { code: "B601", name: "PKG" },
        { code: "C601", name: "PKC" },
        { code: "D601", name: "PKT" },
        { code: "E601", name: "PIM" },
        { code: "F601", name: "PSP" },
      ],
      fittingDataResult: {},
      deskripsi: "",
      unitPrice: "",
      unitPrice: 123000000,
      sukuBunga: 10,
      searchQuery: "",
      sisaUmurPakai: "",
      stockOutEffect: "3700000000",
      loadingCalculate: false,
      loadingRefresh: false,
      loadingSearch: false,
      loadingFittingData: false,
      disableGetData: false,
      disabledCalculate: false,
      disableReset: false,
      loadingReset: false,
      disableOrderCost: false,
      calculationResult: null,
      isStockRequired: false,
      summaryVisible: false,
      matrixDialogVisible: false,
      dataFetched: false,
      loadTimeoutFetchMatNum: null
    };
  },
  computed: {
    ...mapState({
      authUserPermission: (state) => state.auth.userPermission,
      authUser: state => state.auth.user
    }),
    displayStockOutEffect: {
      get() {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(this.stockOutEffect));
      },
      set(newValue) {
        const rawValue = parseInt(newValue.replace(/[^0-9]/g, "")); 
        this.stockOutEffect = isNaN(rawValue) ? 0 : rawValue;
      },
    },
    formattedSukuBunga: {
      get() {
        return `${this.sukuBunga}%`
      },
      set(newValue) {
        const rawValue = parseFloat(newValue.replace('%', ''));
        this.sukuBunga = isNaN(rawValue) ? 0 : rawValue;
      }
    },
    formattedUnitPrice: {
      get() {
        return new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(parseInt(this.fittingDataResult.unit_price));
      },
      set(newValue) {
        const rawValue = parseInt(newValue.replace(/[^0-9]/g, "")); 
        this.fittingDataResult.unit_price = isNaN(rawValue) ? 0 : rawValue;
      },
    },
    isDisabledRunCalcButton(){
      !this.dataFetched ||
      !this.fittingDataResult.unit_price ||
      !this.sukuBunga ||
      !this.stockOutEffect ||
      !this.sisaUmurPakai ||
      this.loadingCalculate ||
      this.loadingRefresh ||
      this.disabledCalculate ||
      this.loadingDownload
    }
  },
  mounted() {
    // this.orderCost = this.formatNumber(5000000, "orderCost");
    this.initializeSelectedPlant();
  },
  methods: {
    initializeSelectedPlant() {
        const mapping = {
          B000: 'B601',
          C000: 'C601',
          D000: 'D601',
          E000: 'E601',
          F000: 'F601',
          G000: 'G601',
          H000: 'H601',
          I000: 'I601',
          J000: 'J601',
        };

        // Cek apakah company_code ada dalam mapping
        if (this.authUser.company_code in mapping) {
          this.getData.plant = mapping[this.authUser.company_code];
        }
    },
    updateSukuBunga(event) {
      // Menghapus simbol % dan mengonversi kembali ke angka
      const rawValue = event.target.value.replace(/[^0-9]/g, "");
      this.sukuBunga = parseFloat(rawValue) || 0;
    },
    updateStockOutEffect(event) {
      const rawValue = event.target.value.replace(/[^0-9]/g, ""); // Hanya ambil angka
      this.stockOutEffect = rawValue; // Simpan sebagai string
    },
    updateUnitPrice(event) {
      // Menghapus format mata uang dan mengonversi kembali ke angka
      const rawValue = event.target.value.replace(/[^\d]/g, "");
      this.fittingDataResult.unit_price = parseFloat(rawValue) || 0;
    },
    handlePlantChange(value) {
      // console.log("Selected Plant:", value);
      // this.getMasterMaterials();
    },
    async handleGetData() {
      this.loadingFittingData = true;
      this.summaryVisible = false
      this.dataFetched = false

      const postData = {
        use_data_ta: true,
        plant: this.getData.plant,
        material_codes: [this.getData.materialNumber.toString()],
      };

      try {
        const response = await axios.post("https://stock-calc-be-v2.pupuk.in/fit-data", postData, { headers: { "Content-Type": "application/json" } });

        if (!response.data[0].deskripsi.includes('tidak ditemukan')) {
          this.fittingDataResult = response.data[0]

          this.fittingDataResult.unit_price = response.data[0].unit_price || 123000000
          this.dataFetched = true;
        
        } else {
          this.$notify({
              message: "Your Data material is not found.",
              type: 'danger',
              verticalAlign: "bottom",
              horizontalAlign: "left"
          });
        }
      } catch (error) {

        this.$notify({
            message: 'Error fetching fitting data: ' + error,
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });

      } finally {
        this.loadingFittingData = false;
      }
    },
    async calculate() {
      // Validasi nilai suku bunga
      const sukuBungaValue = Math.max(1, Math.min(100, this.sukuBunga)); // Pastikan nilai berada dalam rentang 1-100

      const inputData = [
        {
          plant: this.getData.plant,
          material: this.getData.materialNumber.toString(),
          kategori: "Benefit Cost Ratio",
          input_parameter: {
            unit_price: parseFloat(this.fittingDataResult.unit_price.toString().replace(/[^0-9.-]/g, "")) || 0,
            stock_out_effect: parseFloat(this.stockOutEffect.toString().replace(/[^0-9.-]/g, "")) || 0,
            suku_bunga: sukuBungaValue,
            sisa_tahun_pemakaian: parseFloat(this.sisaUmurPakai) || 0,
          },
        },
      ];

      if (inputData[0].input_parameter.sisa_tahun_pemakaian === 0) {
        this.$notify({
            message: "Silakan isi di Sisa Umur Pakai.",
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });
        return false
      }

      if (inputData[0].input_parameter.unit_price === 0) {
        this.$notify({
            message: "Silakan isi di Unit Price.",
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });
        return false
      }

      this.loadingFittingData = true
      this.loadingCalculate = true
      try {
        const response = await axios.post("https://stock-calc-be-v2.pupuk.in/calculate", inputData );
        // console.log("Calculation result:", response.data);

        this.isStockRequired   = response.data[0]?.perlu_penyediaan_stock || false;
        this.calculationResult = response.data;

        // table summary ada
        this.summaryVisible = true;

      } catch (error) {
        // console.log(error)
        this.$notify({
            message: "Your Data material is not found.",
            type: 'danger',
            verticalAlign: "bottom",
            horizontalAlign: "left"
        });

      } finally {
        this.loadingCalculate = false;
        this.loadingFittingData = false;
      }
    },
    fetchSuggestions(queryString, cb) {
      if (!this.getData.plant || queryString.length < 1) return cb([{label: 'No Data'}]);
      // console.log('fetchSuggestions', this.authUser.company_code)

      clearTimeout(this.loadTimeoutFetchMatNum);
      this.loadTimeoutFetchMatNum = setTimeout(async () => {
        this.loadingSearch = true;
        try {
          const response = await axios.get("https://stock-calc-be-v2.pupuk.in/master-material", {
              params: {
                plant: this.getData.plant,
                material: queryString,
              },
            }
          );

          const results = response.data;
          if (results.length > 0) {
            cb(results.slice(0, 500).map((item) => ({
                value: item.MATERIAL,
                label: `${item.MATERIAL} - ${item.DESCRIPTION}`,
                MATERIAL: item.MATERIAL,
                DESCRIPTION: item.DESCRIPTION,
              }))
            );
          } else {
            cb([{label: 'No Data'}])
          }
          
        } catch (error) {
          cb([]);
          this.$notify({
              message: error.message,
              type: "danger",
              verticalAlign: "bottom",
              horizontalAlign: "left"
          });
        } finally {
          this.loadingSearch = false;
        }
      }, 500)
    },
    handleSelectMaterialNumber(selectedItem) {
      this.searchQuery = selectedItem.label;
      this.getData.materialNumber = selectedItem.MATERIAL;
      this.deskripsi = selectedItem.DESCRIPTION; // Simpan deskripsi jika diperlukan
      // console.log("Selected Material Number:", selectedItem);
    },
    resetParameter() {
      // Reset parameter terkait data yang diambil
      this.getData.plant = null;
      this.getData.materialNumber = null;
      this.searchQuery = "";
      this.summaryVisible = false;
      this.fittingDataResult.unit_price = "";
      this.sukuBunga = 10;
      this.stockOutEffect = 3700000000;
      this.sisaUmurPakai = "";
      this.dataFetched = false;
      this.calculationResult = null;
      this.loadingFittingData = false;
      this.loadingFittingData = false;
      this.loadingReset = false;

      // console.log("Parameters reset");
    },

    openMatrixDialog() {
      this.matrixDialogVisible = true;
    },
    handleDialogClose() {
      this.matrixDialogVisible = false;
    },
  },
};
</script>

<style scoped>
.btn-yellow {
  background-color: rgb(252, 165, 3) !important;
  /* Warna latar belakang kuning */
  color: rgb(8, 7, 7) !important;
  /* Warna teks hitam */
  border-color: rgb(221, 201, 68) !important;
  /* Warna tepi kuning */
}

.summary-text {
  font-size: 14px;
  color: #333;
}

/* loading */
.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  animation: spinner-border 0.75s linear infinite;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/* pop up matrix */
.table-container {
  overflow-x: auto;
  /* margin-bottom: 1rem; */
}

.matrix-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 10px; /* Ukuran font lebih kecil */
  min-width: 600px; /* Lebar minimum lebih kecil */
}

.matrix-table th,
.matrix-table td {
  border: 1px solid #0d0909; /* Border lebih tipis */
  padding: 5px; /* Padding lebih kecil */
}

.matrix-table th {
  background-color: #2a4f8a;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.variable-header {
  background-color: #009688;
}

.method-header {
  background-color: #2196f3;
}

.sub-header {
  background-color: #64b5f6;
}

.bcr-header {
  background-color: #ffc107;
}

.matrix-table tbody td {
  background-color: #f9f9f9;
}

.matrix-table tbody tr:nth-child(even) td {
  background-color: #fff;
}

.table-header-center {
  text-align: center;
  font-weight: bold;
}

.base-button {
    display: inline-flex; /* Tombol akan menyesuaikan dengan teks */
    align-items: center; /* Vertikal alignment teks */
    font-size: 0.850rem; /* Ukuran font default (misalnya, 14px) */
    padding: 12px 6px; /* Padding yang konsisten */
    border: none; /* Menghapus border default jika diperlukan */
    text-align: center; /* Menyelaraskan teks di tengah */
    margin: 0; /* Hapus margin */
    min-width: auto; /* Pastikan tombol tidak memiliki lebar minimum tetap */
    transform: scale(1); /* Pastikan transformasi tidak mempengaruhi ukuran */
}

.d-flex {
    display: flex; /* Menggunakan Flexbox untuk tata letak */
    align-items: center; /* Vertikal alignment konten */
    flex-wrap: wrap; /* Membungkus tombol jika ruang tidak cukup */
}

.base-button + .base-button {
    margin-left: 4px; /* Ruang antar tombol jika diperlukan */
}

/* Media query untuk ukuran font responsif jika diperlukan */
@media (max-width: 768px) {
  .base-button {
      font-size: 0.75rem; /* Ukuran font lebih kecil pada layar lebih kecil */
      padding: 4px 8px; /* Padding yang lebih kecil */
  }
    
  .matrix-table {
    font-size: 8px; /* Ukuran font lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    padding: 4px; /* Padding lebih kecil pada layar kecil */
  }

  .matrix-table th,
  .matrix-table td {
    white-space: nowrap; /* Mencegah teks membungkus pada ukuran kecil */
  }
}
</style>
